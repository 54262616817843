@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Comfortaa";
  src: url("assets/fonts/Comfortaa-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter-VariableFont_slnt,wght.ttf");
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

fieldset > legend {
  width: 0 !important;
}

.react-colorful {
  position: absolute !important;
  width: 100% !important;
  top: 36px;
  left: 0;
  height: 200px;
  z-index: 9999;
}

.react-colorful__pointer {
  height: 24px !important;
  width: 24px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
