.biens-chatbot-icon-container {
  cursor: pointer;
  position: absolute;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  transition: 0.5s;
}

.chatbot-img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.chat-icon-play-up {
  animation: 0.5s goUpChatIcon forwards;
}

.chat-icon-play-down {
  animation: 0.5s goDownChatIcon forwards;
}

.biens-chat-window {
  height: calc(100% - 24px);
  width: calc(100% - 36px);
  border-radius: 12px;
  -webkit-box-shadow: 0 0 25px -15px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: 0 0 25px -15px rgba(0, 0, 0, 0.65);
  box-shadow: 0 0 25px -15px rgba(0, 0, 0, 0.65);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 9999;
  background: #fff;
}

.biens-chat-window.close {
  animation: 0.5s goDownChatContainer forwards;
}

/*
  CHAT HEADER START
*/

.biens-chat-header {
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #ddd;
  border-radius: 16px 16px 0 0;
  position: relative;
}

.biens-chat-img {
  position: relative;
}

.biens-chat-img > img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.biens-chat-img > .green-dot {
  height: 10px;
  width: 10px;
  background: #7cfc00;
  border-radius: 50%;
  position: absolute;
  bottom: 3px;
  right: 0;
}

.biens-chat-assistant {
  margin-left: 12px;
}

.biens-chat-assistant > h3 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.biens-chat-assistant > span {
  font-size: 12px;
}

.biens-x-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.biens-x-icon:hover {
  background: #ccc;
  border-radius: 8px;
}

/*
  CHAT HEADER FINISH
*/

/*
  CHAT AREA START
*/

.biens-chat-area {
  -webkit-box-shadow: inset 0px 29px 27px -32px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0px 29px 27px -32px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0px 29px 27px -32px rgba(0, 0, 0, 0.3);
  flex: 1;
  overflow-y: auto;
  padding-bottom: 12px;
}

.biens-chat-message {
  display: flex;
}

.biens-chat-message.assistant {
  justify-content: flex-start;
}

.biens-chat-message.user {
  justify-content: flex-end;
}

.biens-chat-bubble {
  position: relative;
  width: fit-content;
  padding: 8px 12px 16px 8px;
  display: flex;
  align-items: flex-end;
  max-width: 80%;
  min-width: 50px;
  white-space: pre-line;
}

.biens-chat-bubble.assistant {
  border-radius: 8px 8px 8px 0;
  margin: 8px 0 0 12px;
}

.biens-chat-bubble.user {
  border-radius: 8px 8px 0 8px;
  margin: 8px 12px 0 0;
}

.biens-chat-bubble > h6 {
  font-size: 14px;
  margin: 0;
  font-weight: normal;
}

.biens-chat-bubble > h6 > a:visited {
  color: blue;
}

.biens-chat-bubble > span {
  font-size: 10px;
  margin-left: 4px;
  font-weight: normal;
  position: absolute;
  bottom: 4px;
  right: 12px;
}
/*
  CHAT AREA FINISH
*/

/*
  INPUT AREA START
*/

.biens-input-area {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  position: relative;
}

.biens-input-area > input {
  border: none;
  outline: none;
  width: 100%;
  height: 30px;
  padding-right: 12px;
  font-size: 14px;
  background: inherit;
}

.biens-input-area > input:disabled {
  background: inherit;
}

.biens-send-button {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.biens-loading-container {
  position: absolute;
  left: 12px;
  top: -42px;
  border-radius: 8px;
  padding: 8px 12px;
  -webkit-box-shadow: 0 0 7px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 7px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 7px -4px rgba(0, 0, 0, 0.75);
}

.biens-loading-container > *:not(:last-child) {
  margin-right: 4px;
}

.biens-loading-animation {
  animation: pulse 1.33s linear infinite;
}

.biens-loading-animation-slow {
  animation: pulse 1.66s linear infinite;
}

.biens-loading-animation-slowest {
  animation: pulse 2s linear infinite;
}

/*
  INPUT AREA FINISH
*/

/*
  FOOTER AREA START
*/
.biens-chat-footer {
  height: 35px;
  border-top: 1px solid rgb(238, 238, 238);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 12px 12px;
  font-family: "Comfortaa", sans-serif;
}

.biens-chat-footer > span {
  color: #757575;
  margin: 0;
  font-size: 12px;
}

.biens-chat-footer > .biens-link {
  font-size: 12px;
  color: #00a2e8;
  margin-left: 4px;
  text-decoration: none;
  font-weight: bold;
}
/*
  FOOTER AREA FINISH
*/

/*
  MOBILE FIXES
*/

@media (pointer: none), (pointer: coarse) {
  .biens-chatbot-icon-container {
    width: 40px;
    height: 40px;
    right: 20px;
    bottom: 20px;
  }
  .biens-chat-window {
    width: 100%;
    height: 100dvh;
    max-width: unset;
  }
}

@media only screen and (min-width: 81px) {
  .biens-chatbot-icon-container:hover {
    scale: 1.2;
    transition: 0.5s;
  }
}
